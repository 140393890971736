nav{
    display:flex;
    align-items: center;
    background-color:white;
    position: sticky;
    top: 0;
    z-index:99;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
}
.nav-title{
    margin:1rem;
}

nav ul{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
nav ul li{
    list-style: none;
}
nav ul li a img{
    width: 30px;
    height: 30px;
    min-height: 24px;
    min-width: 24px;
}
nav ul li a{
    display: block;
    text-decoration: none;
    color: #333b;
    padding: 0.5rem;
    margin: 0 0.5rem;

}
.active{
    background-color:var(--mountbatten-pink-2);
}
nav ul li a:not(.active):hover{
    background-color: #d7dedc;
}
nav .menu{
    display: none;
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}
nav .menu span{
    height: 0.4rem;
    width: 100%;
    background-color: #fff;
    border-radius: 0.2rem;
}
nav ul.open{
    display: flex;
}

@media(min-width:900px){
    nav .menu-right,
    nav .menu-left{
        display: flex;
    }

    nav .menu-left{
        margin-left: 2rem;
    }
    
    nav .menu-right{
        
    }
}

@media(max-width:900px){

    nav .menu{
        display: flex;
    }
    nav{
        flex-direction: column;
        align-items: start;
    }
    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
        position: absolute; /* Absolute positioning */
        top: 100%;          /* Start just below the nav */
        left: 0;            /* Align left to the nav */
        background-color: #1b1b1b; /* Give it a background */
        border-radius: 0 0 0.5rem 0.5rem;
        box-shadow: 0 0 10px rgba(0,0,0,0.2); 
    }
    nav ul li{
        width: 100%;
        text-align: center;
    }
    nav ul li a{
        margin: 0.2rem 0.5rem;
    }

}